import styled from "styled-components";

export const Container = styled.div`
	ul {
		// marker at global styles
		li {
			padding-block: 1rem;
			b {
				font-weight: 600;
				font-family: var(--font-family-tertiary);
			}
		}
	}
	small {
		color: hsl(0, 0%, 40%);
	}

	.team-section {
		background-color: var(--clr-primary);
		padding: var(--section-padding);

		.member-cards {
			width: 100%;
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			gap: calc(2rem + 0.5vw);
			margin-block: 3rem;

			.member-card {
				width: 100%;

				display: flex;
				gap: 2rem;
				flex-wrap: wrap;
				align-items: center;
				justify-content: flex-start;

				img {
					width: 17rem;
					max-width: 50%;
					height: 12.5rem;
					object-fit: cover;
				}

				.member-info {
					width: 50%;
					p {
						line-break: loose;
						padding: 0.4rem;
					}
				}
			}
		}
	}

	.certificate {
		display: flex;
		flex-direction: column;
		alignt-items: center;
	}

	.legenda {
		text-align: center;
		font-size: 1rem;
	}

	.certificates {
		margin: 3rem;
		justify-content: space-between;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		img {
			width: 400px;
			max-width: 100%;
			height: auto;
			transition: transform 50ms linear;
			&:hover {
				transform: scale(1.05);
			}
		}
		a {
			display: contents;
		}
	}


	@media only screen and (max-width: 880px) {
		.member-card {
			img {
				margin-inline: auto;
			}
			.member-info {
				margin-inline: auto;
			}
		}
	}
`;
